import React from "react";
import moment from "moment";
import qs from "query-string";
import { Skeleton } from "antd";
import { connect } from "react-redux";
import { getEvent } from "../../../actions/eventsActions";
import { getGiftSettings } from "../../../actions/giftsActions";
import { getGroupTicketSettings } from "../../../actions/groupTicketsActions";
import { signOutUser } from "../../../actions/authActions";
import Node from "./Node";
import { withTranslation } from "react-i18next";
import Wrapper from "../../wrappers/Wrapper";
import EventSummary from "../../admin/panels/events/others/EventSummary";
import history from "../../../history";
import Transmission from "./Transmission";
import AdvertisementBlock from "./AdvertisementBlock";
import tc from "../../../helpers/translateContent";
import {
  getTimeToStart,
  getDatetimeRange,
  checkEventStatusByDate,
  getEventTimeDescription,
} from "../../../helpers/productUtilities";
import VimeoChat from "./VimeoChat";
import { facebookPixel, googleTagManager } from "../../../theme-variables";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import _ from "lodash";

class EventPage extends React.Component {
  state = {
    transmission: false,
  };
  getAccessButtonProps = () => {
    const { access } = this.props.event;
    const { t } = this.props;
    if (access) {
      return {
        color: "green",
        disabled: true,
        text: t("videodesc.access"),
      };
    }
    return null;
  };
  getPaymentButtonProps = () => {
    const { t, giftConfig, groupTicketConfig } = this.props;
    const {
      id,
      event_price,
      age_restriction,
      countryBlock,
      access,
      newPrice,
      date_from,
      date_to,
      full_day,
      time_from,
      time_to,
      ticketsAvailable,
      enable_tickets,
      available_tickets,
      ticketsLeft,
    } = this.props.event;
    const eventStatus = checkEventStatusByDate(
      date_from,
      date_to,
      full_day,
      time_from,
      time_to
    );

    if (eventStatus === -1) return null;
    const { birthday } = this.props.user;
    const buttonProps = { disabled: true, color: "red" };
    if (birthday && moment().diff(birthday, "years") < age_restriction)
      buttonProps.text = t("videodesc.age_restriction");
    else if (countryBlock) buttonProps.text = t("videodesc.country_blocked");
    else if (!enable_tickets) return null;
    else if (!access && available_tickets > 0 && ticketsAvailable <= 0)
      buttonProps.text = t("videodesc.no_tickets");
    else if (
      !access ||
      (Number.parseFloat(event_price) !== 0 && giftConfig.gift_available) ||
      (Number.parseFloat(event_price) !== 0 &&
        groupTicketConfig.event_available &&
        (!ticketsLeft || ticketsLeft >= groupTicketConfig.min_units))
    ) {
      buttonProps.color = "white";
      buttonProps.disabled = false;
      buttonProps.onClick = () => history.push(`/platnosc/wydarzenie/${id}`);
      buttonProps.text =
        Number.parseFloat(event_price) === 0 && available_tickets > 0
          ? t("videodesc.get_access")
          : Number.parseFloat(newPrice) === 0
          ? t("videodesc.use_code")
          : t("videodesc.buy");
    }
    return buttonProps;
  };
  getPaymentStatusProps = () => {
    const { t, event } = this.props;
    const { paymentStatus } = event;
    switch (paymentStatus) {
      case "PENDING":
      case "WAITING_FOR_CONFIRMATION":
        return {
          tagColor: "geekblue",
          text: t("payments.pending"),
        };
      case "COMPLETED":
        return {
          tagColor: "green",
          text: t("payments.completed"),
        };
      case "CANCELED":
        return {
          tagColor: "volcano",
          text: t("payments.canceled"),
          renderPrice: true,
        };
      case "REFUNDED":
        return {
          tagColor: "purple",
          text: t("payments.refunded"),
          renderPrice: true,
        };
      case "NOT_STARTED":
      case "UNDEFINED":
      default:
        return {
          renderPrice: true,
        };
    }
  };
  componentDidUpdate(prevProps) {
    if (
      googleTagManager &&
      prevProps.event !== this.props.event &&
      !!this.props.event
    ) {
      const dataLayer = {
        event: "productView",
        page_type: "event",
        page: _.pick(
          this.props.event,
          "id",
          "title",
          "description",
          "subtitle",
          "event_price",
          "additional_desc",
          "age_restriction",
          "duration",
          "date_from",
          "date_to",
          "time_from",
          "time_to",
          "full_day",
          "video_id"
        ),
      };
      TagManager.dataLayer({ ...dataLayer, authenticated: true });
      const { title, id, event_price } = this.props.event;
      TagManager.dataLayer({ ecommerce: null });
      TagManager.dataLayer({
        ecommerce: {
          currencyCode: "PLN",
          impressions: [
            {
              name: title,
              id: id,
              price: event_price,
              category: "event",
            },
          ],
        },
      });
    }
  }
  componentDidMount() {
    if (!this.props.authenticated)
      history.push(history.location.pathname + "/publiczny");
    const transmission = qs.parse(this.props.location.search).ogladaj;
    this.setState({
      transmission: transmission || false,
    });
    const eventId = this.props.match.params.id;
    this.props.getEvent(eventId);
    this.props.getGiftSettings();
    this.props.getGroupTicketSettings();
  }
  getTimeRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  getDateRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  getNearestDateTime = () => {
    const e = this.props.event;
    let nearestDate = getTimeToStart(
      e.date_from,
      e.date_to,
      e.time_from,
      e.time_to,
      e.full_day
    );
    if (nearestDate) nearestDate = nearestDate.add(5, "seconds");
    return nearestDate;
  };
  render() {
    const { loading, event, user, t } = this.props;
    if (!loading && event) {
      const {
        title,
        description,
        date_from,
        date_to,
        time_from,
        time_to,
        full_day,
        event_price,
        newPrice,
        usedCode,
        duration,
        age_restriction,
        teaser_link,
        access,
        available,
        countryBlock,
        gallery,
        image_link,
        video_link,
        accessibility_video,
        autodesc_video,
        translations,
        videoTranslations,
        additional_desc,
        vimeo_chat,
        hide_time_to,
        id,
        slug,
      } = event;
      const { transmission } = this.state;
      const { dateDesc, timeDesc } = getEventTimeDescription(
        full_day,
        date_from,
        date_to,
        time_from,
        time_to,
        t,
        hide_time_to,
        true
      );

      const countdownDate = this.getNearestDateTime();

      if (!transmission)
        return (
          <Wrapper>
            <Node
              title={tc(title, "title", translations)}
              time={timeDesc}
              date={dateDesc}
              countdownText={t("videodesc.video_from")}
              countdownDate={countdownDate}
              forceCountdown={false}
              description={tc(description, "description", videoTranslations)}
              additionalDesc={tc(
                additional_desc,
                "additional_desc",
                videoTranslations
              )}
              paymentButton={this.getPaymentButtonProps()}
              accessButton={this.getAccessButtonProps()}
              paymentStatus={this.getPaymentStatusProps()}
              price={{
                price: event_price,
                newPrice: newPrice,
                code: usedCode,
              }}
              duration={duration}
              ageRestriction={age_restriction}
              teaserLink={tc(teaser_link, "teaser_link", videoTranslations)}
              access={access}
              available={available}
              availableText={t("videodesc.event_on")}
              countryBlock={countryBlock}
              userBirthday={user ? user.birthday : null}
              mainImage={image_link}
              gallery={gallery}
              turnOnTransmission={() => {
                const eventId = this.props.match.params.id;
                this.props.getEvent(eventId);
                setTimeout(() => {
                  if (facebookPixel)
                    ReactPixel.track("ViewContent", {
                      content_ids: [`event-${id}`],
                      content_name: `${title} (${dateDesc})`,
                      content_type: "product",
                      contents: [{ id: `event-${id}`, quantity: 1 }],
                      currency: "PLN",
                      value: parseFloat(event_price),
                    });
                  this.setState({ transmission: true });
                }, 500);
              }}
            />
            {this.props.user && this.props.user.role === "admin" ? (
              <EventSummary eventId={this.props.event.id} />
            ) : null}
          </Wrapper>
        );
      else
        return (
          <Wrapper>
            <Transmission
              title={tc(title, "title", translations)}
              queryParams={this.props.location.search}
              time={timeDesc}
              date={dateDesc}
              price={event_price}
              id={this.props.match.params.id}
              urlVideo={tc(video_link, "video_link", videoTranslations)}
              access={
                access &&
                available &&
                user &&
                moment().diff(user.birthday, "years") >= age_restriction
              }
              urlAccessibility={tc(
                accessibility_video,
                "accessibility_video",
                videoTranslations
              )}
              urlAudiodesc={tc(
                autodesc_video,
                "autodesc_video",
                videoTranslations
              )}
              userToken={this.props.token}
              signOutUser={this.props.signOutUser}
              stopTransmission={() => {
                this.setState({ transmission: false });
              }}
            />
            <VimeoChat chat={vimeo_chat} />
            <AdvertisementBlock isPaid={Number.parseFloat(event_price) !== 0} />
            {this.props.user && this.props.user.role === "admin" ? (
              <EventSummary eventId={this.props.event.id} />
            ) : null}
          </Wrapper>
        );
    } else
      return (
        <Wrapper>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    payment: state.payments,
    giftPayment: state.gifts || { loading: false },
    groupTicketPayment: state.groupTickets || { loading: false },
    user: state.auth.user,
    event: state.events.events.find((v) =>
      /^[0-9]+$/.test(ownProps.match.params.id)
        ? v.id === Number.parseInt(ownProps.match.params.id)
        : v.slug === ownProps.match.params.id
    ),
    loading: state.events.loading,
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    giftConfig: state.gifts.config,
    groupTicketConfig: state.groupTickets.config,
  };
};

export default connect(mapStateToProps, {
  getEvent,
  signOutUser,
  getGiftSettings,
  getGroupTicketSettings,
})(withTranslation()(EventPage));
