import React from "react";
import moment from "moment";
import qs from "query-string";
import { Skeleton } from "antd";
import { connect } from "react-redux";
import { getVideo } from "../../../actions/videosActions";
import { signOutUser } from "../../../actions/authActions";
import { getConfig } from "../../../actions/appConfigActions";
import { getGiftSettings } from "../../../actions/giftsActions";
import { getGroupTicketSettings } from "../../../actions/groupTicketsActions";
import Node from "./Node";
import { withTranslation } from "react-i18next";
import Wrapper from "../../wrappers/Wrapper";
import VideoSummary from "../../admin/panels/videos/others/VideoSummary";
import history from "../../../history";
import Transmission from "./Transmission";
import AdvertisementBlock from "./AdvertisementBlock";
import tc from "../../../helpers/translateContent";
import { facebookPixel, googleTagManager } from "../../../theme-variables";
import ReactPixel from "react-facebook-pixel";
import _ from "lodash";
import TagManager from "react-gtm-module";

class VideoPage extends React.Component {
  state = {
    transmission: false,
  };
  getAccessButtonProps = () => {
    const { access, expiration_date, end_date } = this.props.video;
    const { t } = this.props;
    const expirationDate =
      expiration_date && expiration_date !== "0000-00-00"
        ? `${t("videodesc.have_access_to")}: ${
            end_date && moment(end_date).isBefore(moment(expiration_date))
              ? moment(end_date).format("H:mm, DD.MM.YYYY")
              : moment(expiration_date).format("H:mm, DD.MM.YYYY")
          }`
        : null;
    if (access) {
      return {
        color: "green",
        disabled: true,
        text: t("videodesc.access"),
        additionalDesc: expirationDate,
      };
    }
    return null;
  };
  getPaymentButtonProps = () => {
    const { t, ticketsExpirationTime, giftConfig, groupTicketConfig } =
      this.props;
    const {
      id,
      age_restriction,
      countryBlock,
      access,
      newPrice,
      end_date,
      available_from,
      video_price,
      tickets_expiration_time,
    } = this.props.video;
    const { birthday } = this.props.user;
    if (moment(end_date).isBefore(moment())) return null;
    const buttonProps = { disabled: true, color: "red" };

    const expTimeOverriden = tickets_expiration_time
      ? tickets_expiration_time
      : tickets_expiration_time === 0
      ? null
      : ticketsExpirationTime;

    const expirationText = expTimeOverriden
      ? `${t("videodesc.access_to")}: ${
          end_date &&
          moment(end_date).isBefore(moment().add(expTimeOverriden, "hours"))
            ? moment(end_date).format("H:mm, DD.MM.YYYY")
            : available_from && moment(available_from).isAfter(moment())
            ? moment(available_from)
                .add(expTimeOverriden, "hours")
                .format("H:mm, DD.MM.YYYY")
            : moment().add(expTimeOverriden, "hours").format("H:mm, DD.MM.YYYY")
        }`
      : null;

    if (birthday && moment().diff(birthday, "years") < age_restriction)
      buttonProps.text = t("videodesc.age_restriction");
    else if (countryBlock) buttonProps.text = t("videodesc.country_blocked");
    else if (
      !access ||
      (Number.parseFloat(video_price) !== 0 && giftConfig.gift_available) ||
      (Number.parseFloat(video_price) !== 0 &&
        groupTicketConfig.video_available)
    ) {
      buttonProps.color = "white";
      buttonProps.disabled = false;
      buttonProps.onClick = () => history.push(`/platnosc/wideo/${id}`);
      buttonProps.text =
        Number.parseFloat(newPrice) === 0
          ? t("videodesc.use_code")
          : t("videodesc.buy");
      buttonProps.additionalDesc = expirationText;
    }

    return buttonProps;
  };
  getPaymentStatusProps = () => {
    const { t, video } = this.props;
    const { paymentStatus } = video;
    switch (paymentStatus) {
      case "PENDING":
      case "WAITING_FOR_CONFIRMATION":
        return {
          tagColor: "geekblue",
          text: t("payments.pending"),
        };
      case "COMPLETED":
        return {
          tagColor: "green",
          text: t("payments.completed"),
        };
      case "CANCELED":
        return {
          tagColor: "volcano",
          text: t("payments.canceled"),
          renderPrice: true,
        };
      case "REFUNDED":
        return {
          tagColor: "purple",
          text: t("payments.refunded"),
          renderPrice: true,
        };
      case "NOT_STARTED":
      case "UNDEFINED":
      default:
        return {
          renderPrice: true,
        };
    }
  };
  componentDidMount() {
    if (!this.props.authenticated)
      history.push(history.location.pathname + "/publiczny");
    const transmission = qs.parse(this.props.location.search).ogladaj;
    this.setState({
      transmission: transmission || false,
    });
    const videoId = this.props.match.params.id;
    this.props.getVideo(videoId);
    this.props.getConfig(false);
    this.props.getGiftSettings();
    this.props.getGroupTicketSettings();
  }
  componentDidUpdate(prevProps) {
    if (
      googleTagManager &&
      prevProps.video !== this.props.video &&
      !!this.props.video
    ) {
      const dataLayer = {
        event: "productView",
        page: _.pick(
          this.props.video,
          "id",
          "title",
          "description",
          "subtitle",
          "video_price",
          "additional_desc",
          "age_restriction",
          "duration",
          "available_from",
          "created_at"
        ),
      };
      TagManager.dataLayer({ ...dataLayer, authenticated: true });
      const { title, id, video_price } = this.props.video;
      TagManager.dataLayer({ ecommerce: null });
      TagManager.dataLayer({
        ecommerce: {
          currencyCode: "PLN",
          impressions: [
            {
              name: title,
              id: id,
              price: video_price,
              category: "video",
            },
          ],
        },
      });
    }
  }
  render() {
    const { loading, video, user, t } = this.props;
    if (!loading && video) {
      const {
        title,
        available_from,
        description,
        video_price,
        newPrice,
        usedCode,
        duration,
        age_restriction,
        teaser_link,
        access,
        available,
        countryBlock,
        gallery,
        image_link,
        video_link,
        accessibility_video,
        autodesc_video,
        translations,
        additional_desc,
        end_date,
        id,
      } = video;
      const { transmission } = this.state;
      let datetime;
      if (available_from && available_from !== "0000-00-00")
        datetime = moment(available_from);
      const time = datetime ? datetime.format("H:mm") : undefined;
      const date = datetime ? datetime.format("DD.MM.YYYY") : undefined;

      let countdownText = t("videodesc.video_from");
      let countdownDate =
        available_from && moment(available_from).isAfter(moment())
          ? moment(available_from)
          : undefined;

      if (
        available &&
        end_date &&
        end_date !== "0000-00-00" &&
        moment().isBefore(moment(end_date))
      ) {
        countdownText = t("videodesc.video_to");
        countdownDate = moment(end_date);
        let diff = moment.duration(countdownDate.diff(moment()));
        if (diff.asHours() > 24) countdownText = t("videodesc.video_to_2");
      }

      if (!transmission)
        return (
          <Wrapper>
            <Node
              title={tc(title, "title", translations)}
              productType="video"
              time={time}
              date={date}
              countdownText={countdownText}
              countdownDate={countdownDate}
              forceCountdown={countdownDate ? true : false}
              description={tc(description, "description", translations)}
              additionalDesc={tc(
                additional_desc,
                "additional_desc",
                translations
              )}
              accessButton={this.getAccessButtonProps()}
              paymentButton={this.getPaymentButtonProps()}
              paymentStatus={this.getPaymentStatusProps()}
              price={{
                price: video_price,
                newPrice: newPrice,
                code: usedCode,
              }}
              duration={duration}
              ageRestriction={age_restriction}
              teaserLink={tc(teaser_link, "teaser_link", translations)}
              access={access}
              available={available}
              availableText={t("videodesc.video_on")}
              countryBlock={countryBlock}
              userBirthday={user ? user.birthday : null}
              mainImage={image_link}
              gallery={gallery}
              turnOnTransmission={() => {
                const videoId = this.props.match.params.id;
                this.props.getVideo(videoId);
                this.setState({ transmission: true });
              }}
            />
            {this.props.user && this.props.user.role === "admin" ? (
              <VideoSummary videoId={this.props.video.id} />
            ) : null}
          </Wrapper>
        );
      else
        return (
          <Wrapper>
            <Transmission
              title={tc(title, "title", translations)}
              queryParams={this.props.location.search}
              time={time}
              date={date}
              price={video_price}
              id={this.props.match.params.id}
              urlVideo={tc(video_link, "video_link", translations)}
              access={
                access &&
                available &&
                user &&
                moment().diff(user.birthday, "years") >= age_restriction
              }
              urlAccessibility={tc(
                accessibility_video,
                "accessibility_video",
                translations
              )}
              urlAudiodesc={tc(autodesc_video, "autodesc_video", translations)}
              userToken={this.props.token}
              signOutUser={this.props.signOutUser}
              stopTransmission={() => {
                this.setState({ transmission: false });
              }}
            />
            <AdvertisementBlock isPaid={Number.parseFloat(video_price) !== 0} />
            {this.props.user && this.props.user.role === "admin" ? (
              <VideoSummary videoId={this.props.video.id} />
            ) : null}
          </Wrapper>
        );
    } else
      return (
        <Wrapper>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    payment: state.videoPayments,
    ticketsExpirationTime: state.appConfig.config.tickets_expiration_time,
    user: state.auth.user,
    video: state.videos.videos.find((v) =>
      /^[0-9]+$/.test(ownProps.match.params.id)
        ? v.id === Number.parseInt(ownProps.match.params.id)
        : v.slug === ownProps.match.params.id
    ),
    loading: state.videos.loading,
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    giftConfig: state.gifts.config,
    groupTicketConfig: state.groupTickets.config,
  };
};

export default connect(mapStateToProps, {
  getVideo,
  signOutUser,
  getConfig,
  getGiftSettings,
  getGroupTicketSettings,
})(withTranslation()(VideoPage));
